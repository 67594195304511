export default {
  count: 0,
  initial: false,
  agenda: {},
  prepareSearch: '',
  filters: {
    days: false,
    types: false,
    zones: false,
    search: false,
    tags: false,
    lang: false,
    ps: false,
  },
  startDay: false,
  showPubs: false,
  applicationTitle: 'ММКЯ',
  event: false,
  reload: false,
  favorites: [],
  showFavorites: false,
  breakpoint: false,
  socialNetworks: [
    // { action: 'facebook', icon: ['fab', 'facebook-square'] },
    { action: 'vk', icon: ['fab', 'vk'] },
    { action: 'odnoklassniki', icon: ['fab', 'odnoklassniki'] },
    { action: 'email', icon: ['far', 'envelope'] },
    // { action: 'clipboard', icon: ['fas', 'link'] },
  ],
  themes: [],
  zones: [],
};
