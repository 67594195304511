/* eslint-disable no-param-reassign */
export default {
  filter: (state) => (key) => state.filters[key],
  filtersQuery: (state) => {
    const query = {};
    let { days } = state.filters;
    if (Array.isArray(days)) [, days] = days;
    if (['soon', 'now'].indexOf(days) === -1) {
      state.filters.days = days && window.moment(days).isValid() ? state.filters.days : false;
    }
    Object.keys(state.filters).forEach((key) => {
      try {
        let value = state.filters[key];
        if (Array.isArray(value)) [, value] = value;
        if (value !== false && typeof value !== 'undefined'
            && value.toString().length > 0) query[key] = value;
      } catch (e) {}
    });
    return query;
  },
};
