import { render, staticRenderFns } from "./App.vue?vue&type=template&id=31ba240c&scoped=true&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ba240c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VApp,VAppBar,VAppBarNavIcon,VBtn,VContainer,VFlex,VLayout,VMain,VNavigationDrawer,VSpacer})


/* vuetify-loader */
import installDirectives from "!../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Mutate from 'vuetify/lib/directives/mutate'
installDirectives(component, {Mutate})
