import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (from.name === 'event') {
      return `#${from.params.event}`;
    }
    return savedPosition;
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
      redirect: '/events/',
    },
    {
      path: '/events',
      name: 'agenda',
      component: () => import(/* webpackChunkName: "agenda" */ './views/Agenda.vue'),
    },
    {
      path: '/events/:event',
      name: 'event',
      component: () => import(/* webpackChunkName: "event" */ './components/Event.vue'),
    },
    {
      path: '/publishers',
      name: 'publishers',
      component: () => import(/* webpackChunkName: "publishers" */ './components/Publishers.vue'),
    },
    { path: '*', redirect: '/' },
  ],
});
