import Axios from 'axios';
import Moment from 'moment';
import router from '@/router';

function isValidDate(d) {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
}

/* eslint-disable no-param-reassign */
export default ({
  commit, getters, state, dispatch,
}) => {
  const query = [];
  if (state.count === 0) {
    state.count += 1;
    Object.keys(state.filters)
      .forEach((key) => {
        let value = router.currentRoute.query[key];
        value = value ? value.trim() : '';
        if (key === 'days' && value && value.length > 0) {
          // value = [Moment(value).format('DD/MM').toString(), value];
        }
        if (key === 'lang') {
          // if (['en', 'ru'].indexOf(value) === -1) value = 'ru';
          value = '';
        }
        if (value.length > 0) query.push({ key, value });
      });
  }
  if (query.length > 0) {
    dispatch('setFilter', query);
  } else {
    Axios
      .get('https://events.knigivgorode.ru/api/events/v2/mibf/2022', {
        params: getters.filtersQuery,
      })
      .then((result) => {
        const data = typeof result.data === 'object' ? result.data : {};
        state.startDay = data.startDay ? data.startDay : false;
        const filters = typeof data.filters === 'object' ? data.filters : {};
        data.filters = filters;
        const events = Array.isArray(data.events) ? data.events : [];
        data.events = events;

        filters.days = filters.days && Array.isArray(data.filters.days) ? filters.days : [];
        filters.days = filters.days.map((d) => {
          let text = false;
          let type = 'date';
          if (d === 'soon' || d === 'now') {
            type = 'text';
            if (d === 'soon') text = 'Скоро';
            if (d === 'now') text = 'Сейчас';
          } else {
            text = d && Moment(d).isValid() ? Moment(d).format('DD/MM').toString() : false;
          }
          return { text, value: d, type };
        }).filter((e) => e.value !== false);

        data.events = events
          .map((event) => ({
            ...event,
            visible: true,
            favorites: state.favorites.indexOf(event.id) !== -1,
          }));

        commit('setAgenda', data);

        state.reload = false;

        const initialFilter = Object.keys(state.filters)
          .filter((key) => key !== 'lang' && state.filters[key] !== false); // && key !== 'search');
        if (data.events.length === 0 && initialFilter.indexOf('days') !== -1) {
          const check = data.filters.days.filter((e) => e.value === state.filters.days);
          if (check.length === 0) {
            dispatch('setFilter', [{ key: 'days', value: data.filters.days[data.filters.days.length - 1] }]);
            return;
          }
        }
        if (initialFilter.length === 0 && filters.days.length > 0) {
          state.reload = true;
          let delta = Infinity;
          let cDay = filters.days[0];
          filters.days.forEach((d) => {
            const testDay = typeof d === 'string' ? d : d.value;
            if (testDay && isValidDate(testDay)) {
              const testDelta = Math.abs(window.moment(testDay).unix() - window.moment(window.moment().format('YYYY-MM-DD')).unix());
              if (testDelta < delta) {
                delta = testDelta;
                cDay = d;
              }
            }
          });
          if (cDay.type !== 'text' && window.moment(cDay.value).unix() < window.moment(state.startDay[1]).unix()) {
            cDay = { text: state.startDay[0], value: state.startDay[1] };
          } else {
            cDay = { text: state.startDay[0], value: state.startDay[1] };
          }
          dispatch('setFilter', [{ key: 'days', value: cDay }]);
        } else {
          state.reload = false;
        }

        if (state.showFavorites !== true) state.showFavorites = false;
      })
      .catch(() => {
      });
  }
};
