<template>
    <v-app style="background-color: #d9dddd;" v-mutate="onMutate">
      <v-app-bar v-if="breakpoint < 1024" ref="toolbar"
        fixed app dense color="white" class="white--text text-uppercase">
        <v-app-bar-nav-icon class="black--text"
          @click.stop="drawer = true" aria-label="Настройки поиска"/>
        <!--
        <a class="black--text" href="https://knigivgorode.ru"><span class="body-2 font-weight-light black--text">
          ММКЯ 2022
        </span></a>
        -->
        <span class="body-2 font-weight-light black--text">
          ММКЯ 2022
        </span>
        <v-spacer/>
        <span class="black--text font-weight-black subheading">
          {{
            [ Array.isArray(filters.days) ? filters.days[1] : filters.days ]
            | moment('DD/MM','','')
          }}
        </span>
      </v-app-bar>

      <v-navigation-drawer app flat
        :permanent="breakpoint >= 1024"
        v-model="drawer"
        disable-route-watcher
        v-bind:style="{
          // 'background-color': '#cd3231 !important',
          'background-image': `url(${bgimage})`,
          'background-repeat': 'repeat-y',
          'background-image': `linear-gradient(#e1fb04, #e1fb04)`,
          width: $vuetify.breakpoint.width < 375 ? '250px !important' : '300px !important'
        }"
      >
          <v-layout fluid fixed pa-0 ma-0 wrap row>
            <v-flex xs12>
              <!-- <a href='https://knigivgorode.ru/'> -->
              <img :alt="applicationTitle" :title="applicationTitle"
                src="@/assets/MMKYA_2022_logo_300x146.jpg"
                ref="logo"
                style="width: 100%; height: auto;"/><!-- </a> -->
              <div>
              <!--- :style="{ paddingTop: ($refs.logo && $refs.logo.height || 0) + 'px' }"> -->
                <social-sharing
                  :url="socialParams.url"
                  :title="socialParams.title"
                  :description="socialParams.quote"
                  :quote="socialParams.quote"
                  hashtags="ммкя2022,ммкя"
                  twitter-user=""
                  inline-template
                  :class="{
                    'pl-2': true,
                    visibilityHidden: !($route.name === 'agenda' || !event)
                  }"
                >
                  <div>
                    <network
                      v-for="item in $store.state.socialNetworks"
                      :network="item.action"
                      :key="item.action"
                    >
                      <v-btn
                        small text icon color="black" :aria-label="'Поделиться: ' + item.action"
                      >
                        <font-awesome-icon :icon="item.icon" />
                      </v-btn>
                    </network>
                    <v-btn
                      small text icon color="black" :aria-label="'Скопировать адрес'"
                      @click="$copyText($getLocation())"
                    >
                      <font-awesome-icon :icon="['fas', 'link']" />
                    </v-btn>
                  </div>
                </social-sharing>
                <Filters
                  v-on:close-drawer="closeDrawer"/>
              </div>
            </v-flex>
          </v-layout>
      </v-navigation-drawer>
      <v-main
        v-bind:style="{
          'padding-left': $vuetify.breakpoint.width < 769 ? '0px !important' : '300px !important',
        }"
        >
        <v-container ref="main" fluid>
          <router-view/>
        </v-container>
      </v-main>
    </v-app>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapState } from 'vuex';
import Filters from '@/components/Filters.vue';

// eslint-disable-next-line no-unused-vars
const BG = require('@/assets/menu_md.png');

export default {
  components: {
    Filters,
  },
  data() {
    return {
      drawer: false,
      mainWidth: 0,
      hideTimeout: false,
      drawerCounter: 0,
      bgimage: BG,
    };
  },
  mounted() {
    this.onMutate();
    this.updateBreakpoint();
    const storage = localStorage || {};
    if (storage && typeof storage.getItem === 'function') {
      let fav = storage.getItem('favorites');
      if (fav) {
        try {
          fav = JSON.parse(fav);
          if (Array.isArray(fav)) {
            this.$store.dispatch('updateFavorites', fav);
          }
        } catch (e) {}
      }
    }
  },
  methods: {
    updateBreakpoint() {
      const val = this.$vuetify.breakpoint.width;
      let bp = 375;
      if (val >= 0 && val <= 768) bp = 375;
      if (val > 768 && val < 1024) bp = 1024;
      if (val >= 1024 && val < 1680) bp = 1680;
      if (val >= 1680) bp = 1920;
      this.$store.dispatch('updateBreakpoint', bp);
    },
    closeDrawer() {
      this.drawer = false;
    },
    onMutate() {
      let height = 0;
      const { toolbar, main } = this.$refs;
      if (main) {
        this.mainWidth = main.clientWidth;
        // console.log(main.clientWidth);
      }
      if (toolbar) {
        height = `${4 + toolbar.$el.offsetHeight}px`;
      } else {
        height = '4px';
      }
      document.documentElement.style.setProperty('--headerHeight', height);
    },
  },
  computed: {
    ...mapState(['favorites', 'filters', 'event', 'applicationTitle', 'breakpoint']),
    socialParams() {
      const title = [];
      const quote = [];
      const what = [this.applicationTitle];
      const { filters } = this;
      const { event } = this;

      let day = filters.days || '';
      if (day && Array.isArray(day)) [day] = day;
      if (day) title.push(day);

      if (!event) {
        if (filters.zones !== false) title.push(filters.zones);
        if (filters.types !== false) what.push(filters.types);
        if (filters.days !== false) quote.push(`Когда: ${filters.days}`);
        quote.push(`Что: ${what.join(', ')}`);
        if (filters.zones !== false) quote.push(`Где: ${filters.zones}`);
      }

      title.push(this.applicationTitle);
      return {
        title: title.join(', '),
        url: window.location.toString(),
        quote: quote.join('\n'),
      };
    },
  },
  watch: {
    '$route.name': {
      handler() {
        if (this.$route.name === 'agenda') this.drawer = true;
        if (this.drawerCounter > 0) this.drawer = false;
        this.drawerCounter += 1;
      },
    },
    filters: {
      deep: true,
      handler() {
        clearTimeout(this.hideTimeout);
        this.hideTimeout = setTimeout(() => {
          // this.drawer = false;
        }, 5000);
      },
    },
    '$store.state.filters.lang': {
      handler(value) {
        this.$i18n.locale = value;
      },
    },
    favorites: {
      handler() {
        const storage = localStorage || {};
        if (storage && typeof storage.setItem === 'function') {
          const fav = this.favorites;
          storage.setItem('favorites', JSON.stringify(fav));
        }
      },
      deep: true,
    },
    '$vuetify.breakpoint.width': {
      handler() {
        this.updateBreakpoint();
      },
    },
  },
};
</script>

<style>
  * {
    font-family: 'Formular Light';
    word-break: normal;
  }
  .v-toolbar {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .visibilityHidden {
    visibility: hidden;
    opacity:0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
</style>
<style scoped>
</style>
