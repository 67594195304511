<template>
<div>
  <v-dialog
    v-model="showMap"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <div :style="{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${ require('@/assets/MMKYA_2022_Plan.jpg') })`,
        filter: 'blur(10px)',
        '-webkit-filter': 'blur(8px)' }
      " />
      <div :style="{
        position: 'absolute',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: '#6FBE4E',
        backgroundImage: `url(${ require('@/assets/MMKYA_2022_Plan.jpg') })` }
      " />
    </v-card>
    <v-fade-transition>
        <v-btn
          fixed
          dark
          fab
          top
          right
          color="red"
          small
          @click="showMap = false"
          aria-label="Закрыть"
          class="top"
        >
          <v-icon>close</v-icon>
        </v-btn>
    </v-fade-transition>
  </v-dialog>
  <v-list v-if="agenda && agenda.filters" light
    :style="{ marginBottom: $vuetify.breakpoint.width < 375 ? '125px' : '0px' }"
  >
    <v-list-item v-if="langs.length > 1">
      <v-layout row wrap align-center>
        <v-flex xs3 v-for="item in langs" v-bind:key="item.id">
          <v-chip
            small style="margin-left: 0px;" color="error"
            :disabled = 'item.id === $i18n.locale'
            @click="changeLanguage($event, item.id)"
          ><div :class="{ active: item.id !== $i18n.locale }">{{item.name}}</div></v-chip>
        </v-flex>
      </v-layout>
    </v-list-item>
    <v-list-item ref="search" v-if="$route.name !== 'publishers'" class="mr-0">
      <v-list-item-content class="pa-0 pr-0">
        <v-row class="pa-0 ma-0">
          <v-col cols="7" class="pa-0 ma-0" align-self="center">
            <v-text-field
              light
              color="black"
              v-model="search"
              single-line
              :label="$t('message.search')"
              clearable
              v-on:keyup.enter="pressEnter"
              dense
              hide-details
            />
          </v-col>
          <v-col cols="5" class="pt-0 pb-0 ma-0 pr-0" align-self="center">
            <!--
            <v-btn
              icon
              :disabled="!search || search.length < 3"
              small @click.stop="doSearch" dark class="black--text" color="black"
              :aria-label="$t('message.search')"
            >
              <v-icon>search</v-icon>
            </v-btn>
            -->
            <v-btn
              :disabled="!search || search.length < 3"
              small @click.stop="doSearch" light class="black--text mr-0"
              :aria-label="$t('message.search')"
              style="float: right"
            >
              {{ $t('message.gosearch') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <div
      v-for="(filter, index) in filterGroups.filter(e => e.render !== false)"
      :key="`${filter.key}--${componentsVersion}--${index}`"
    >
      <v-select
        v-if="!filter.autocomplete && filter.render !== false && filter.type !== 'text' &&
          (!Array.isArray(filter.pages) || filter.pages.indexOf($route.name) !== -1)"
        v-on:input="filterClick($event, filter)"
        @click:clear="clear($event, filter)"
        class="pl-4 pr-4 pb-3 pt-3" dense
        :clearable="filter.clearable && filter.model !== false"
        :key="`${filter.key}--${componentsVersion}`"
        v-model="filter.model"
        :items="getSource(filter)"
        :label="filter.model ? $t(filter.title) : $t(filter.title)"
        item-text="text"
        item-value="value"
        hide-details
        single-line
        no-data-text=""
        :menu-props="{ auto: true }"
      >
      </v-select>
      <v-autocomplete
        v-if="filter.autocomplete && filter.render !== false && filter.type !== 'text' &&
          (!Array.isArray(filter.pages) || filter.pages.indexOf($route.name) !== -1)"
        v-on:input="filterClick($event, filter)"
        @click:clear="clear($event, filter)"
        class="pl-4 pr-4 pb-3 pt-3" dense
        :clearable="filter.clearable && filter.model !== false"
        :key="`${filter.key}--${componentsVersion}`"
        v-model="filter.model"
        :items="getSource(filter)"
        :label="filter.model ? $t(filter.title) : $t(filter.title)"
        item-text="text"
        item-value="value"
        hide-details
        single-line
        no-data-text=""
        :menu-props="{ auto: true }"
      >
      </v-autocomplete>
    </div>

    <div>
      <v-select
        v-if="$route.name !== 'publishers'"
        class="pl-4 pr-4 pb-3 pt-3" dense
        @click="showPubs"
        :key="`showpubs-key-${componentsVersion}`"
        :items="[]"
        label="Издатели"
        hide-details
        single-line
        no-data-text=""
      ></v-select>
    </div>
    <div>
      <v-select
        class="pl-4 pr-4 pb-3 pt-3" dense
        @click="showMaps"
        :key="`showmap-key-${componentsVersion}`"
        :items="[]"
        label="План ярмарки"
        hide-details
        single-line
        no-data-text=""
      ></v-select>
    </div>
    <div>
      <v-select
        class="pl-4 pr-4 pb-3 pt-3" dense
        @click="showMapPDF"
        :key="`pdfmap-key-${componentsVersion}`"
        :items="[]"
        hide-details
        single-line
        no-data-text=""
      >
        <template v-slot:label>
          Схема ярмарки [<v-icon>mdi-file-pdf-box</v-icon>]
        </template>
      </v-select>
    </div>
    <!--
    <div class="pl-4 pr-4">
      <v-btn small>Фестиваль независимых авторов</v-btn>
      <v-button>Издательская программа правительства Москвы</v-button>
    </div> -->
    <!--
    <v-list-group
      v-for="item in filterGroups.filter(e => e.render !== false)"
      :key="item.key"
      v-model="item.active"
      class="itemGroup"
      @click="filterClick($event, item)"
    >
      <template v-slot:activator>
        <v-list-item class="pa-0 ma-0">
          <v-list-item-content>
              {{item.active ?
                $t(item.title)  :
                getItemTitle(filters[item.key]) || $t(item.title)}}
          </v-list-item-content>
          <v-list-item-icon style="align-self: center;">
            <v-icon v-if="item.clearable
                          && filters
                          && filters[item.key] !== false"
              @click.stop="clear($event, item)">close</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>

      <v-list-item
        :dark="true"
        v-for="subItem in agenda.filters[item.key]"
        @click.stop="click($event, item, subItem, item.reset)"
        :key="getItemKey(subItem)"
        :class="{
          item: false, selected: isSelected(subItem, item.key), 'pa-0': true, 'ma-0': true
        }"
      >
        <v-list-item-content class="pl-4 pr-4">
          {{ getItemTitle(subItem) }}
        </v-list-item-content>
      </v-list-item>
    </v-list-group> -->
    <!--
    <v-list-item>
      <v-layout row wrap align-center>
        <v-flex xs12>&nbsp;</v-flex>
        <v-flex xs12 v-for="item in tags" v-bind:key="item.tag">
          <v-chip v-if="(!item.langs || item.langs.indexOf($i18n.locale) !== -1)
                         && (filters.tags !== $t(item.tag + 'Tag'))"
            small style="margin-left: 0px;" color="error"
            @click="click($event, {key: 'tags'}, $t(item.tag + 'Tag'), true)"
          >{{ $t(item.tag) }}</v-chip>
        </v-flex>
      </v-layout>
    </v-list-item>
    -->
  </v-list>
</div>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint-disable spaced-comment */
/* eslint-disable prefer-destructuring */

import { mapState } from 'vuex';
// import { clearTimeout } from 'timers';

export default {
  name: 'Filters',
  data() {
    return {
      showMap: false,
      langs: [
        { id: 'ru', name: 'рус' },
        // { id: 'en', name: 'eng' },
      ],
      searchTimeout: null,
      filtersTimeout: null,
      search: '',
      componentsVersion: 0,
      filterGroups: [
        {
          key: 'days',
          title: 'message.days',
          active: false,
          clearable: true,
          model: false,
          pages: ['agenda', 'event'],
        },
        {
          key: 'types',
          src: {
            publishers: 'themes',
          },
          title: 'message.themes',
          active: false,
          clearable: true,
          model: false,
          pages: ['agenda', 'event'], //, 'publishers'],
        },
        {
          key: 'zones',
          src: {
            publishers: 'zones',
          },
          title: 'message.places',
          active: false,
          clearable: true,
          model: false,
          pages: ['agenda', 'event'], // , 'publishers'],
        },
        {
          key: 'ps',
          title: 'message.ps',
          autocomplete: false,
          active: false,
          clearable: true,
          reset: true,
          model: false,
          pages: ['agenda', 'event'],
        },
        /**
        {
          key: 'publishers',
          title: 'message.publishers',
          active: false,
          clearable: false,
          reset: true,
          click: 'showPubs',
          model: false,
        }, **/
        /**
        {
          key: 'tags',
          title: 'message.tags',
          active: false,
          clearable: true,
          reset: true,
        }, **/

        // { key: 'search', title: 'message.search', render: false },
      ],
      tags: [
        // { tag: 'message.bologna' },
        // { tag: 'message.holiday', langs: ['ru'] },
        // { tag: 'message.bookin', langs: ['ru'] },
        // { tag: 'message.illustrator' },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.setModels();
      },
    },
    search() {
      this.$store.dispatch('updatePrepareSearch', this.search);
      // clearTimeout(this.searchTimeout);
      if (!this.search) {
        this.doSearch(true);
      }
    },
    filterGroups: {
      deep: true,
      handler() {
      },
    },
  },
  mounted() {
    this.setModels(true);
  },
  computed: {
    ...mapState(['agenda', 'filters']),
  },
  methods: {
    showMapPDF() {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: 'https://events.knigivgorode.ru/pdfs/MMKYA_2022_Plan.pdf',
      }).click();
      this.componentsVersion += 1;
    },
    getSource(fit) {
      const key = fit?.src?.[this.$route.name] || false;
      if (key && this.$store.state[key]) {
        return this.$store.state[key];
      }
      return this?.agenda?.filters[fit?.key];
    },
    setModels(firstTime) {
      if (!firstTime && process.env.NODE_ENV !== 'development') {
        let tmp = JSON.parse(JSON.stringify(this.filters));
        tmp = Object.entries(tmp).filter((e) => e[1] !== false).map((e) => `${e[0]}=${e[1]}`).join('; ');
        this.$gtag.event('update_filters', { event_category: 'engagement', event_label: tmp });
        this.$gtag.pageview(this.$route);
      }
      this.search = this.filters.search || '';
      this.filterGroups.forEach((group) => {
        if (group.key === 'types' && this.filters.types === 'ОНЛАЙН-МАРАФОН') {
          window.location.replace('https://vk.com/video/playlist/-98267658_6');
          return;
        }
        group.model = this.filters[group.key] || false;
      });
    },
    changeLanguage(event, lang) {
      if (lang === this.$i18n.locale) return;
      this.$i18n.locale = lang;
      this.click(false, { key: 'lang' }, lang, true);
    },
    pressEnter() {
      if (this.search && this.search.length > 2) this.doSearch();
    },
    getItemKey(item) {
      return Array.isArray(item) ? item[1] : item;
    },
    getItemTitle(item) {
      return Array.isArray(item) ? item[0] : item;
    },
    isSelected(item, key) {
      const value = Array.isArray(item) ? item[1] : item;
      let filter = this.$store.getters.filter(key);
      if (Array.isArray(this.$store.getters.filter(key))) {
        [, filter] = filter;
      }
      return value === filter;
    },
    getGroupTitle(item) {
      let { title } = item;

      if (item.active) {
        const tmp = this.$store.getters.filter(item.keys);
        if (tmp && tmp.toString().length > 0) title = tmp;
      }

      return title;
    },
    // eslint-disable-next-line no-unused-vars
    filterClick(value, item) {
      this.componentsVersion += 1;
      if (item.click) {
        this[item.click]();
      }
      this.click(null, item, value, item.reset);
    },
    showPubs() {
      this.componentsVersion += 1;
      if (process.env.NODE_ENV !== 'development') {
        this.$gtag.event('show_publishers', { event_category: 'engagement', event_label: this.$route.fullPath });
      }
      this.$router.push({ name: 'publishers' });
    },
    showMaps() {
      if (process.env.NODE_ENV !== 'development') {
        this.$gtag.event('show_map', { event_category: 'engagement', event_label: this.$route.fullPath });
      }
      this.componentsVersion += 1;
      this.showMap = true;
    },
    click(event, item, vls, reset, clearDays) {
      const self = this;
      let fls = [];

      const search = (self.search || '').trim();
      if (search) {
        fls.push({ key: 'search', value: search });
      }
      const tmp = {};
      self.filterGroups.forEach((v) => {
        tmp[v.key] = v;
      });
      if (item.reset && item.model) {
        self.filterGroups.forEach((v) => {
          if (v.key !== item.key) {
            v.model = false;
            fls.push({ key: v.key, value: false });
          } else {
            fls.push({ key: v.key, value: v.model || false });
          }
        });
      } else {
        this.filterGroups.forEach((group) => {
          if (group.reset && group.key !== item.key) {
            group.model = false;
          }
          fls.push({ key: group.key, value: group.model || false });
        });
      }

      if (clearDays) {
        fls = fls.filter((e) => e.key !== 'days');
        fls.push({ key: 'days', value: false });
      }
      const isEmpty = fls.filter((e) => e.value).length === 0;
      if (isEmpty) {
        const df = fls.filter((e) => e.key === 'days')[0];
        const value = this.$store.state.startDay[1];
        if (df) {
          df.value = value;
          fls.push({
            key: 'days',
            value,
          });
        }
      }

      setTimeout(() => {
        self.$store.dispatch('setFilter', fls);
        self.$vuetify.goTo(0, { duration: 0 });
        self.hideEvent();
      }, 300);
    },
    clear(event, item) {
      if (item.clearable) {
        item.active = false;
        const fls = [{ key: item.key, value: false }];
        const search = (this.search || '').trim();
        if (item.key !== 'search' && search) {
          fls.push({ key: 'search', value: search });
        }
        this.$store.dispatch('setFilter', fls);
        this.$vuetify.goTo(0, { duration: 0 });
        this.hideEvent();
      }
    },
    doSearch(notClose) {
      if (this.search && this.search?.length > 2 && process.env.NODE_ENV !== 'development') {
        this.$gtag.event('search', { event_label: this.search });
      }
      this.$store.dispatch('setFilter', [{ key: 'search', value: this.search || false }]);
      // this.click(null, { reset: true, model: true }, null, true);
      this.$vuetify.goTo(0, { duration: 0 });
      if (notClose !== true) this.$emit('close-drawer');
      this.hideEvent();
    },
    hideEvent() {
      const self = this;
      setTimeout(() => {
        if (self.$route.name === 'event') {
          const nav = {
            path: '/events/',
            query: self.$store.getters.filtersQuery,
            // hash: this.$route.params.event,
          };
          self.$router.push(nav);
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
  .active {
    cursor: pointer !important;
  }
  .selected {
    background-color: rgba(0,0,0,0.5);
    font-weight: bold !important;
  }
  .item {
    height: auto !important;
  }
  .itemGroup {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
</style>

<style>
  .v-list-item {
    height: auto !important;
  }
  .v-list-item__title {
    font-size: 1rem !important;
    line-height: 20px !important;
    text-transform: uppercase;
  }
  .v-list-item__icon {
    color: black !important;
  }
  .v-select__selection {
    height: auto !important;
    text-overflow: unset !important;
    white-space: normal !important;
  }
  .v-list-item__title {
    height: auto !important;
    text-overflow: unset !important;
    white-space: normal !important;
  }
</style>
