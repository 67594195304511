import router from '@/router';

/* eslint-disable no-param-reassign */
export default ({
  commit, dispatch, state, getters,
}, filters) => {
  let needUpdate = false;

  let dropDay = false;

  filters.forEach((filter) => {
    const prev = state.filters[filter.key];
    commit('setFilter', filter);
    if (prev !== filter.value) needUpdate = true;
    if (filter.key === 'zones' && filter.value === 'МЕДИАТЕКА') dropDay = true;
  });
  if (dropDay) commit('setFilter', { key: 'days', value: '' });
  if (needUpdate) {
    const nav = {
      // path: '/events/',
    };
    const query = getters.filtersQuery;
    if (Object.keys(query).length > 0) nav.query = query;
    const hash = router.currentRoute.hash || '';
    if (hash && hash.length > 0) nav.hash = hash;
    router
      .push(nav)
      .catch(() => {
        // console.log(error);
      });
    dispatch('updateEvents');
  }
};
