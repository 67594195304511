/* eslint-disable no-use-before-define */
// import 'event-source-polyfill';
import moment from 'moment';

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import SocialSharing from 'vue-social-sharing';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'vuetify/dist/vuetify.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faFacebookSquare, faVk, faOdnoklassniki } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faHeart, faClipboard } from '@fortawesome/free-regular-svg-icons';
import VueClipboard from 'vue-clipboard2';
import VueGtag from 'vue-gtag';
import vuetify from './plugins/vuetify';

// import { mapState } from 'vuex';
import App from './App.vue';
import router from './router';
import store from './store/store';

import messages from './messages';

if (process.env.NODE_ENV !== 'development') {
  Vue.use(VueGtag, {
    appName: 'ММКЯ-2022',
    config: { id: 'UA-80205246-2' },
  }, router);
}

Vue.use(VueClipboard);

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'ru', // set locale
  messages, // set locale messages
});

Vue.config.productionTip = false;

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(faFacebookSquare, faVk, faOdnoklassniki, faEnvelope, faHeart, faClipboard, faLink);

/**
Vue.use(Vuetify, {
  iconfont: 'md',
});
* */

Vue.use(SocialSharing);

Vue.prototype.$getLocation = () => {
  let url = window.location.toString();

  // console.log(app.$route.name);
  if (app.$route.name === 'event') {
    // eslint-disable-next-line prefer-destructuring
    url = url.split('?')[0];
  }
  return url;
};

// Add moment filter
window.moment = moment;
Vue.filter('moment', (input, format, delimiter = '–', utc = '+0300') => {
  // console.log(input);
  // console.log(store.getters.filter);
  let dates = Array.isArray(input) ? input : [input];
  if (!input) dates = [];
  const result = dates
    .filter((date) => date || false)
    .map((date) => {
      const tmp = moment(date).utcOffset(utc);
      return tmp.isValid() ? tmp.format(format) : ' ';
    })
    .join(delimiter);
  return result;
});

const app = new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
