import updateEvents from './updateEvents';
import setFilter from './setFilter';

/* eslint-disable no-param-reassign */
export default {
  updatePrepareSearch({ commit }, value) {
    commit('setPrepareSearch', value);
  },
  updateLoadCount({ commit }, value) {
    commit('setLoadCount', value);
  },
  updateCurrentEvent({
    commit,
  }, event) {
    commit('setCurrentEvent', event);
  },
  updateShowFavorites({
    commit,
  }, value) {
    commit('setShowFavorites', value);
  },
  updateBreakpoint({
    commit,
  }, breakpoint) {
    commit('setBreakpoint', breakpoint);
  },
  updateFavorites({
    commit,
  }, favorites) {
    commit('setFavorites', favorites);
  },
  switchPubs({
    commit,
  }) {
    commit('switchPubs');
  },
  setFilter,
  updateEvents,
};
