export default {
  en: {
    message: {
      search: 'Search',
      gosearch: 'Go',
      days: 'All days',
      themes: 'All themes',
      places: 'All places',
      tags: 'All tags',
      ps: 'All participants',
      bolognaTag: 'Bologna in Moscow',
      bologna: 'Bologna in Moscow',
    },
  },
  ru: {
    message: {
      ticketInfo: 'Для того, чтобы избежать очереди на входе, просим Вас заранее выбрать день посещения, сеанс и оформить бесплатный билет:',
      search: 'Поиск',
      gosearch: 'Искать',
      days: 'Все дни',
      themes: 'Все темы',
      places: 'Все площадки',
      tags: 'Все теги',
      ps: 'Все участники',
      publishers: 'Все издатели',
      bolognaTag: 'Болонья в Москве',
      bologna: 'Болонья в Москве',
      holidayTag: 'Выходные иллюстратора',
      holiday: 'Выходные иллюстратора',
      bookinTag: 'Книги в городе',
      bookin: 'Книги в городе',
    },
  },
};
