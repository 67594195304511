/* eslint-disable no-param-reassign */
export default {
  setFilter(state, filter) {
    state.filters[filter.key] = filter.value?.value || filter.value;
  },
  setAgenda(state, agenda) {
    state.agenda = agenda;
  },
  setFavorites(state, favorites) {
    state.favorites = favorites;
  },
  setBreakpoint(state, breakpoint) {
    state.breakpoint = breakpoint;
  },
  setShowFavorites(state, value) {
    state.showFavorites = value;
  },
  setCurrentEvent(state, event) {
    state.event = event;
  },
  setLoadCount(state, value) {
    state.count = value;
  },
  setPrepareSearch(state, value) {
    state.prepareSearch = value;
  },
  switchPubs(state) {
    state.showPubs = !state.showPubs;
  },
};
