var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{directives:[{name:"mutate",rawName:"v-mutate",value:(_vm.onMutate),expression:"onMutate"}],staticStyle:{"background-color":"#d9dddd"}},[(_vm.breakpoint < 1024)?_c('v-app-bar',{ref:"toolbar",staticClass:"white--text text-uppercase",attrs:{"fixed":"","app":"","dense":"","color":"white"}},[_c('v-app-bar-nav-icon',{staticClass:"black--text",attrs:{"aria-label":"Настройки поиска"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = true}}}),_c('span',{staticClass:"body-2 font-weight-light black--text"},[_vm._v(" ММКЯ 2022 ")]),_c('v-spacer'),_c('span',{staticClass:"black--text font-weight-black subheading"},[_vm._v(" "+_vm._s(_vm._f("moment")([ Array.isArray(_vm.filters.days) ? _vm.filters.days[1] : _vm.filters.days ],'DD/MM','',''))+" ")])],1):_vm._e(),_c('v-navigation-drawer',{style:({
      // 'background-color': '#cd3231 !important',
      'background-image': ("url(" + _vm.bgimage + ")"),
      'background-repeat': 'repeat-y',
      'background-image': "linear-gradient(#e1fb04, #e1fb04)",
      width: _vm.$vuetify.breakpoint.width < 375 ? '250px !important' : '300px !important'
    }),attrs:{"app":"","flat":"","permanent":_vm.breakpoint >= 1024,"disable-route-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-layout',{attrs:{"fluid":"","fixed":"","pa-0":"","ma-0":"","wrap":"","row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('img',{ref:"logo",staticStyle:{"width":"100%","height":"auto"},attrs:{"alt":_vm.applicationTitle,"title":_vm.applicationTitle,"src":require("@/assets/MMKYA_2022_logo_300x146.jpg")}}),_c('div',[_c('social-sharing',{class:{
                'pl-2': true,
                visibilityHidden: !(_vm.$route.name === 'agenda' || !_vm.event)
              },attrs:{"url":_vm.socialParams.url,"title":_vm.socialParams.title,"description":_vm.socialParams.quote,"quote":_vm.socialParams.quote,"hashtags":"ммкя2022,ммкя","twitter-user":""},inlineTemplate:{render:function(){var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.$store.state.socialNetworks),function(item){return _c('network',{key:item.action,attrs:{"network":item.action}},[_c('v-btn',{attrs:{"small":"","text":"","icon":"","color":"black","aria-label":'Поделиться: ' + item.action}},[_c('font-awesome-icon',{attrs:{"icon":item.icon}})],1)],1)}),_c('v-btn',{attrs:{"small":"","text":"","icon":"","color":"black","aria-label":'Скопировать адрес'},on:{"click":function($event){_vm.$copyText(_vm.$getLocation())}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'link']}})],1)],2)},staticRenderFns:[]}}),_c('Filters',{on:{"close-drawer":_vm.closeDrawer}})],1)])],1)],1),_c('v-main',{style:({
      'padding-left': _vm.$vuetify.breakpoint.width < 769 ? '0px !important' : '300px !important',
    })},[_c('v-container',{ref:"main",attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }